.reactMoneyInputGroup{
    position: relative;
    display: flex;
    border-collapse: separate;
    -webkit-box-align: stretch;
    align-items: stretch;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
}
.reactMoneyInputGroupPrepend {
    margin-left: 5px;
    margin-right: -1px;
    display: flex;
    padding: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    line-height: 26px;
    color: #7a7a7a;
    font-weight: bold;
}
.reactMoneyInputGroupAppend {
    right: 0px;
    margin-left: -1px;
    display: flex;
    padding: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 26px;
    color: #7a7a7a;
    font-weight: bold;
    position: absolute;
}
.reactMoneyInput {
    width: 100%;
    padding-left: 20px;
    line-height: 30px;
    font-size: 0.9em;
}
